import React, { useContext } from "react"
import clsx from "clsx"
import Layout from "@components/Layout/Layout"
import Container from "@components/Container/Container.jsx"
import ButtonLink from "@components/Buttons/ButtonLink.jsx"
import MainWrap from "@components/Wrap/MainWrap"
import { Typography } from "@material-ui/core"
import { Grid } from "@material-ui/core"
import Chip from "@components/Cards/Chip.jsx"
import Avatar from "@material-ui/core/Avatar"
import { makeComponentStyles } from "@components/mixins.js"
import infoLabel from "@static/images/Info.svg"
import { graphql } from "gatsby"
import Markdown from "markdown-to-jsx"
import { IntlContextConsumer, useIntl } from "gatsby-plugin-react-intl"
import { getStrapiContentByLang } from "../heplers.js"
import FloatWrap from "@components/Wrap/FloatWrap.jsx"
import SimpleSlider from "@components/Slider/Slider"
import { SwiperSlide } from "swiper/react"
import Seo from "@components/seo"

const styles = ({ palette, breakpoints }) => ({
  text: {
    fontSize: "18px",
    color: palette.text.primary,
    "& $b": {
      fontWeight: 500
    },
    [breakpoints.down("sm")]: {
      fontSize: "14px",
      color: palette.text.black
    }
  },
  chiptext: {
    fontSize: "18px",
    [breakpoints.down("sm")]: {
      fontSize: "14px"
    }
  },
  title: {
    fontSize: "22px",
    fontWeight: 500,
    paddingBottom: "20px"
  },
  linker: {
    color: palette.primary.main,
    [breakpoints.up("md")]: {
      fontSize: "18px !important",
    }
  },
  card: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    textAlign: "center",
    background: palette.primary.light,
    [breakpoints.down('sm')]: {
      padding: 0,
    },
    [breakpoints.up('md')]: {
      padding: '20px 24px',
      height: '100%',
    }
  },
  swiper_slide: {
    height: "auto !important",
    background: palette.primary.light,
    padding: "30px !important",
    textAlign: "center",
    [breakpoints.down("sm")]: {
      padding: "20px 24px"
    },
  },
  slide: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    textAlign: "center",
    height: "100%",
  },
})

function go(link) {
  window.open(link, "_blank")
}

const Article = (props) => {
  const {
    text,
    title,
    linker,
    swiper_slide,
    slide,
  } = makeComponentStyles(styles)

  return (
    <Grid item xs={12} style={{marginTop: "40px"}}>
      <Typography className={title}>{props.title}</Typography>
      <Typography className={text}><Markdown>{props.description}</Markdown></Typography>
      <Grid container spacing={3} style={{ paddingTop: "30px" }}>
        <SimpleSlider>
          {
            props.restaurants.map(({
                                     Address,
                                     Description,
                                     Name,
                                     Phone,
                                     Site
                                   }) => (
              <SwiperSlide className={swiper_slide}>
                <div className={slide} >
                  <Typography className={text}><b>{Name}:</b> {Address}</Typography>
                  {Phone ? <Typography className={text}>{Phone}</Typography> : null}
                  {Site
                    // className={linker}
                    ? <ButtonLink onClick={() => go(Site)}>
                        <Typography className={clsx(text, linker)}><b>{Site}</b></Typography>
                      </ButtonLink>
                    : null
                  }
                  {Description ? <Typography
                    className={text}><Markdown>{Description}</Markdown></Typography> : null}
                </div>
              </SwiperSlide>
            ))
          }
        </SimpleSlider>
      </Grid>
    </Grid>
)
}

const Food = ({ data }) => {
  const { language } = useContext(IntlContextConsumer)
  const intl = useIntl()

  const {
    text,
    chiptext,
    card,
  } = makeComponentStyles(styles)

  const {
    ImportantInfo,
    ImportantImage,
    Shops,
    Info,
    InfoImage,
    Articles,
    FoodSEO,
  } = getStrapiContentByLang(data.allStrapiFood.edges, language)

  return (
    <div>
      <Seo title={FoodSEO?.TitleSEO}
           description={FoodSEO?.DescriptionSEO}
           keywords={FoodSEO?.KeywordsSEO.split(',')}
      />

      <>
        <Layout>
          <Container>
            <MainWrap>
              <MainWrap.Header>
                <Typography component={"h1"} variant={"h2"}>
                  {intl.formatMessage({ id: "food" })}
                </Typography>
              </MainWrap.Header>
              <MainWrap.Body>
                <Grid container spacing={3}>
                  <Grid item xs={12}>
                    <FloatWrap className={text} Img={InfoImage.localFile.publicURL} Float="right">
                      <Markdown>{Info}</Markdown>
                    </FloatWrap>
                  </Grid>
                  <Grid item xs={12}>
                    <Chip avatar={<Avatar src={infoLabel} />} label={intl.formatMessage({ id: "important" })} straight
                          className={chiptext} />
                  </Grid>
                  <Grid item xs={12} style={{paddingBottom: "40px"}}>
                    <FloatWrap className={text} Img={ImportantImage.localFile.publicURL} Float="left" displayNone={true}>
                      <Markdown>{ImportantInfo}</Markdown>
                    </FloatWrap>
                  </Grid>
                  {
                    Shops.map(({ Name, ShopAddress, WorkStart, WorkEnd }) => (
                      <Grid item xs={12} md={4}>
                        <Container className={card}>
                          <div>
                            <Typography className={text}><b>{Name}</b></Typography>
                            {
                              ShopAddress.map(({ Address }) => (
                                <Typography className={text}>{Address}</Typography>
                              ))
                            }
                            <Typography className={text}>
                              {intl.formatMessage({ id: "workingtime" })}: {WorkStart.substr(0, WorkStart.lastIndexOf(":"))}-{WorkEnd.substr(0, WorkEnd.lastIndexOf(":"))}
                            </Typography>
                          </div>
                        </Container>
                      </Grid>
                    ))
                  }

                  {
                    Articles.map(({
                                    Title,
                                    Description,
                                    Restaurants
                                  }) => (
                      <Article
                        title={Title}
                        description={Description}
                        restaurants={Restaurants}
                      />
                    ))
                  }
                </Grid>
              </MainWrap.Body>
            </MainWrap>
          </Container>
        </Layout>
      </>
    </div>
  )
}

export default Food
export const pageQuery = graphql`{
 allStrapiFood {
    edges {
      node {
        ImportantInfo
        Info
        ImportantImage {
          localFile {
            publicURL
          }
        }
        Shops {
          Name
          ShopAddress {
            Address
          }
          WorkEnd
          WorkStart
        }
        Articles {
          Title
          Restaurants {
            Address
            Description
            Site
            Phone
            Name
          }
          Description
        }
        InfoImage {
          localFile {
            publicURL
          }
        }
        locale
        FoodSEO {
          DescriptionSEO
          KeywordsSEO
          TitleSEO
        }
      }
    }
  }
}`
